import React, { useState, useEffect } from "react";
import MyImage from "./webAppLogo.png";
import "@fontsource/noto-sans-myanmar";
import MyImage2 from "./webAppLogo2.png";
import { Link } from "react-router-dom";

const NavigationModule = ({
  onHelpClick,
  onIOSClick,
  onForecastClick,
  tryDiverssetClicked,
  onBlockClicked,
  onNewsClicked,
}) => {
  const [smallSize, setSmallSize] = useState(false);
  const [showSecondNavBar, setShowSecondNavBar] = useState(false);

  useEffect(() => {
    // Update the state based on the screen width
    const handleResize = () => {
      if (window.innerWidth < 740) {
        setSmallSize(true);
      } else {
        setSmallSize(false);
      }
    };

    // Initial state check on mount
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="navigationBar">
      <div className="imageWithText">
        <img className="image" src={MyImage} alt="Diversset logo" />
        <h1>DIVERSSET</h1>
      </div>
      {smallSize ? (
        <div>
          <div className="imageWithText2">
            <img className="image2" src={MyImage2} alt="Menu logo" />
            <div className="toggleButton">
              <button
                className={`menuButton ${showSecondNavBar ? "active" : ""}`}
                onClick={() => setShowSecondNavBar(!showSecondNavBar)}
              >
                <h1 className="menuText">MENU</h1>
              </button>
            </div>
            {showSecondNavBar && (
              <div className="navigationBar secondNavBar">
                <div className="h3Elements">
                  <h4 id="education">
                    <a
                      className="linkColor"
                      href="https://www.udemy.com/course/financial-instruments-and-analysis/?referralCode=CFD1565180443B63D1F7"
                    >
                      EDUCATION
                    </a>
                  </h4>
                  <h4 id="forecast">
                    <Link
                      className="linkColor"
                      onClick={onForecastClick}
                      to="/ideas"
                    >
                      FORECAST
                    </Link>
                  </h4>
                  <h4 id="blog">
                    <Link
                      className="linkColor"
                      onClick={onBlockClicked}
                      to="/stockMarketBlog"
                    >
                      BLOG
                    </Link>
                  </h4>
                  <h4 id="blog">
                  <Link className="linkColor" onClick={onNewsClicked} to="/news" >NEWS</Link>
                  </h4>
                  
                  <h4 id="iosApps">
                    <Link
                      className="linkColor"
                      onClick={onIOSClick}
                      to="/stockMarketiOSApps"
                    >
                      iOS APPS
                    </Link>
                  </h4>
                  <h4 id="fiverr">
                    <Link
                      className="linkColor"
                      onClick={tryDiverssetClicked}
                      to="/stockScreenerAndPortfolioBuilder"
                    >
                      DIVERSSET
                    </Link>
                  </h4>
                  <h4 id="help">
                    <Link
                      className="linkColor"
                      onClick={onHelpClick}
                      to="/help"
                    >
                      HELP
                    </Link>
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="h3Elements">
          <h3 id="education">
            <a
              className="linkColor"
              href="https://www.udemy.com/course/financial-instruments-and-analysis/?referralCode=CFD1565180443B63D1F7"
            >
              EDUCATION
            </a>
          </h3>
          <h3 id="forecast">
            <Link className="linkColor" onClick={onForecastClick} to="/ideas">
              FORECAST
            </Link>
          </h3>
          <h3 id="news">
            <Link className="linkColor" onClick={onNewsClicked} to="/news">
              NEWS
            </Link>
          </h3>
         
          <h3 id="blog">
            <Link
              className="linkColor"
              onClick={onBlockClicked}
              to="/stockMarketBlog"
            >
              BLOG
            </Link>
          </h3>
          <h3 id="iosApps">
            <Link
              className="linkColor"
              onClick={onIOSClick}
              to="/stockMarketiOSApps"
            >
              iOS APPS
            </Link>
          </h3>
          <h3 id="fiverr">
            <Link
              className="linkColor"
              onClick={tryDiverssetClicked}
              to="/stockScreenerAndPortfolioBuilder"
            >
              DIVERSSET
            </Link>
          </h3>
          <h3 id="help">
            <Link className="linkColor" onClick={onHelpClick} to="/help">
              HELP
            </Link>
          </h3>
        </div>
      )}
    </div>
  );
};
export default NavigationModule;
