import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase";
import { HelmetProvider } from "react-helmet-async";

import Auth from "./Auth"; 
import NavigationModule from "./ApperBar";
import MainPageContent from "./MainPageContent";
import HelpComponent from "./Help";
import IOSAppsComponent from "./IOSApps";
import ForecasterAndAnalytics from "./Forecaster";
import StockScreener from "./stockScreenerAndPortfolioBuilder/StockAndETFScreener";
import EfficientPortfolio from "./stockScreenerAndPortfolioBuilder/EfficientPortfolioBuilder";
import BlogSection from "./Blog";
import BlogLst from "./BlogLst";
import BlogRedirect from "./BlogRedirect";

import News from "./News";
import NewsDetails from "./NewsDetails";



function App() {
  const [currentComponent, setCurrentComponent] = useState("A");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleComponentChange = (newComponent) => {
    setCurrentComponent(newComponent);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  
  

  useEffect(() => {
    if (window.location.pathname === "/help") handleComponentChange("B");
    if (window.location.pathname === "/stockScreenerAndPortfolioBuilder") handleComponentChange("E");
    if (window.location.pathname === "/stockMarketiOSApps") handleComponentChange("C");
    if (window.location.pathname === "/stockMarketBlog") handleComponentChange("F");
    if (window.location.pathname === "/ideas") handleComponentChange("D");
    // if (window.location.pathname === "/news") handleComponentChange("G"); // NOT needed if we remove the condition for /news
  }, []);


  return (
    <HelmetProvider>
    <BrowserRouter>
      <div className="App">
        <NavigationModule
          onHelpClick={() => handleComponentChange("B")}
          onIOSClick={() => handleComponentChange("C")}
          onForecastClick={() => handleComponentChange("D")}
          tryDiverssetClicked={() => handleComponentChange("E")}
          onBlockClicked={() => handleComponentChange("F")}
          // onNewsClicked = {() => handleComponentChange("G")}
        />

        <Routes>
          {/* Public Routes */}
          <Route
            path=""
            element={
              currentComponent === "A" && (
                <MainPageContent tryDiverssetClicked={() => handleComponentChange("E")} />
              )
            }
          />
          <Route
            path="/help"
            element={
              currentComponent === "B" && (
                <HelpComponent homeClicked={() => handleComponentChange("A")}
                onLogout={user ? handleLogout : null} 
                />
              )
            }
          />
          <Route
            path="/stockMarketiOSApps"
            element={
              currentComponent === "C" && (
                <IOSAppsComponent homeClicked={() => handleComponentChange("A")} />
              )
            }
          />

          
          <Route
              path="/ideas"
              element={<ForecasterAndAnalytics homeClicked={() => handleComponentChange("A")} />}
            />
          <Route
            path="/stockScreenerAndPortfolioBuilder"
            element={
              user ? (
                currentComponent === "E" && (
                  <StockScreener homeClicked={() => handleComponentChange("A")} />
                )
              ) : (
                <Navigate to="/auth" state={{ from: "/stockScreenerAndPortfolioBuilder" }} replace />
              )
            }
          />
           <Route
              path="/blog/:heading"
              element={<BlogLst homeClicked={() => handleComponentChange("A")}/>}
            />
            <Route
              path="/stockMarketBlog"
              element={
                currentComponent === "F" && (
                  <BlogSection homeClicked={() => handleComponentChange("A")} />
                )
              }
            />

          <Route path="/auth" element={<Auth />} />


          <Route
              path="/news"
              element={<News homeClicked={() => handleComponentChange("A")} />}
            />
          <Route path="/news/:newsId" element={<NewsDetails />} />
          
        </Routes>
      </div>
    </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;