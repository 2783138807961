import "./App.scss";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useEffect, useState, CSSProperties } from "react";

import AdsBlocker from "./AdsBlocker";
import { useDetectAdBlock } from "adblock-detect-react";
import Ads from "./AdsComponent";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/ClipLoader";
import { getBlogPosts } from "./stockScreenerAndPortfolioBuilder/sourceForEndpoint";
import { useDispatch, useSelector } from "react-redux";

/* ===== Material UI imports ===== */
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button
} from "@mui/material";

import { Helmet } from "react-helmet-async";

const BlogSection = ({ homeClicked }) => {
  const dispatch = useDispatch();
  const adBlockDetected = useDetectAdBlock();

  const [blogPosts, setBlogPosts] = useState([]);
  const [blogStatus, setBlogStatus] = useState(blogPosts.map(() => false));
  const [rotatingSpinner, setRotatingSpinner] = useState(false);

  const maxLines = 2;
  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };
  const imageStyle = {
    width: "20%",
    height: "auto",
    marginRight: "10px",
  };
  const postBodyStyle = {
    flex: "1",
    maxHeight: `${maxLines * 2}em`,
    overflow: "hidden",
  };

  const blogStatusChange = (index) => {
    const newStatusArray = [...blogStatus];
    newStatusArray[index] = !newStatusArray[index];
    setBlogStatus(newStatusArray);
  };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    marginTop: "15%",
  };

  const blogs = useSelector((state) => state.filtered.blogPosts);

  useEffect(() => {
    if (blogPosts.length === 0) {
      setRotatingSpinner(true);
      dispatch(getBlogPosts({}));

      setTimeout(() => {
        if (blogs) {
          setBlogPosts(blogs);
        }
      }, 500);
    } else {
      setRotatingSpinner(false);
    }
  }, [blogs]);

  const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html);
  };

  return (
    <div>
      <Helmet>
        <title>Stock Market Blog | Guide To The Markets</title>
        <meta
          name="description"
          content="Guide To The Markets. Read the latest stock market analysis, trading insights, equity valuation, and portfolio management tips in our Stock Market Blog."
        />
        <meta property="og:title" content="STOCK MARKET BLOG | Guide To The Markets" />
        <meta property="og:description" content="Read the latest stock market analysis..." />
        <meta property="og:url" content="https://diversset.com/stockMarketBlog" />
        <meta property="og:type" content="website" />
      </Helmet>
      {adBlockDetected ? (
        <AdsBlocker />
      ) : (
        <div>
          <Ads />

          {/* Top bar with Home button + title */}
          <div className="upperContentAndButton">
            <Link to="/">
              <button className="tryApp1" onClick={homeClicked}>
                Home
              </button>
            </Link>
            <h1 className="helpLabel">STOCK MARKET BLOG</h1>
          </div>

          <h3 className="subHeading">
            Stock market-related blog. Equity valuation, risk management,
            portfolio management, trading.
          </h3>

          {blogPosts.length > 0 ? (
            <div className="buttonContainer">
              <div className="blog-list">
                {blogPosts.map((post, index) => (
                  <Card
                    className="blog-post"
                    key={index}
                    sx={{
                      mb: 2,          // margin bottom
                      p: 2,           // padding
                      boxShadow: 2,   // slight elevation
                      cursor: "pointer",
                    }}
                    onClick={() => blogStatusChange(index)}
                  >
                    {/* Title Link */}
                    <Link
                      to={`/blog/${post.heading}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography variant="h5" gutterBottom>
                        {post.heading.toUpperCase()}
                      </Typography>
                    </Link>

                    {blogStatus[index] ? (
                      <Box>
                        <CardMedia
                          component="img"
                          image={`data:image/jpeg;base64,${post.picture}`}
                          alt={`Post ${index}`}
                          sx={{
                            maxWidth: "100%",
                            height: "auto",
                            borderRadius: 1,
                            mb: 2,
                          }}
                        />
                        <CardContent
                          className="postBody"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(post.body),
                          }}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CardMedia
                          component="img"
                          image={`data:image/jpeg;base64,${post.picture}`}
                          alt={`Post ${index}`}
                          sx={{
                            width: "20%",
                            maxWidth: "200px",
                            height: "auto",
                            borderRadius: 1,
                            mr: 2,
                          }}
                        />
                        <Box
                          className="postBody"
                          sx={{
                            overflow: "hidden",
                            maxHeight: "4em", // about 2 lines
                            lineHeight: "1.5em",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(post.body),
                          }}
                        />
                      </Box>
                    )}
                  </Card>
                ))}
              </div>
            </div>
          ) : (
            <MoonLoader
              color={"#003049"}
              loading={rotatingSpinner}
              cssOverride={override}
              size={60}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BlogSection;