import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AdsLeftRight from "./AdsLeftRight";
import MoonLoader from "react-spinners/ClipLoader"; 

function transformHTML(originalHTML) {
  if (!originalHTML) return "";

  let replaced = originalHTML.replace(
    /<a([^>]*)>/gi,
    `<a$1 target="_blank" rel="noreferrer" style="color: #007aff; text-decoration: none;">`
  );

  return replaced;
}

function NewsDetails({ homeClicked }) {
  const { newsId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchArticle() {
      try {
        setLoading(true);
        const res = await fetch("https://diversset.com/api/fmp/articles");
        const data = await res.json();

        const decoded = decodeURIComponent(newsId);
        const found = data.content.find((item) => item.link === decoded) || null;
        setArticle(found);
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchArticle();
  }, [newsId]);

  if (loading) {
    return (
      <div style={{ margin: "3rem", textAlign: "center" }}>
        <MoonLoader size={60} color="#666" />
      </div>
    );
  }
  if (!article) {
    return <div style={{ padding: "1rem" }}>Article not found.</div>;
  }

  
  const transformedBody = transformHTML(article.content || "");

  
  const snippet = transformedBody.replace(/<[^>]*>/g, "").slice(0, 150);
  const imageUrl = article.image || null;

  return (
    <div>
      {/* Ads at top, just like in BlogLst */}
      <AdsLeftRight />

      {/* “Back to News” button on the left */}
      <div className="upperContentAndButton" style={{ marginBottom: "1rem" }}>
        <Link to="/news" style={{ textDecoration: "none" }}>
          <button
            className="tryApp1"
            onClick={homeClicked}
            style={{ marginLeft: "40px", cursor: "pointer" }}
          >
            Back to News
          </button>
        </Link>
      </div>

      <div className="blog-main-container" style={{ margin: "0 auto", maxWidth: "800px" }}>
        {/* Helmet for SEO */}
        <Helmet>
          <title>{article.title} | Latest Stock Market and Financial News</title>
          <meta name="description" content={snippet} />
          {/* Open Graph */}
          <meta property="og:title" content={article.title} />
          <meta property="og:description" content={snippet} />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={`https://diversset.com/news/${encodeURIComponent(newsId)}`}
          />
          {imageUrl && <meta property="og:image" content={imageUrl} />}

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={article.title} />
          <meta name="twitter:description" content={snippet} />
          {imageUrl && <meta name="twitter:image" content={imageUrl} />}
        </Helmet>

        <h1
          className="blog-heading"
          style={{ textAlign: "center", marginBottom: "1rem" }}
        >
          {article.title}
        </h1>

        {/* If there's an image, show it. */}
        {imageUrl && (
          <div className="blog-img-container" style={{ textAlign: "center", marginBottom: "1rem" }}>
            <img
              src={imageUrl}
              alt={article.title}
              className="blog-img"
              style={{ maxWidth: "100%", borderRadius: "6px" }}
            />
          </div>
        )}

        {/* Date and author row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            fontStyle: "italic",
            color: "#777",
            fontSize: "0.9rem",
          }}
        >
          <span>{article.date}</span>
          <span>By: {article.author || "Unknown"}</span>
        </div>

       
        <div
          className="blog-body-content"
          style={{ lineHeight: "1.6", textAlign: "justify" }}
          dangerouslySetInnerHTML={{ __html: transformedBody }}
        />

        
        <div
          style={{
            marginTop: "2rem",
            borderTop: "1px solid #eee",
            paddingTop: "1rem",
            textAlign: "center",
          }}
        >
          <p style={{ margin: "0" }}>
            To find best stocks to buy right now use the iOS app{" "}
            <a
              href="https://apps.apple.com/ge/app/stocks-2-buy/id6444135874"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#007aff" }}
            >
              Stocks 2 Buy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;