import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useDetectAdBlock } from "adblock-detect-react";
import AdsBlocker from "./AdsBlocker";
import Ads from "./AdsComponent";
import "./App.scss";
import MoonLoader from "react-spinners/ClipLoader";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

const ForecasterAndAnalytics = ({ homeClicked }) => {
  const adBlockDetected = useDetectAdBlock();
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

  const firebaseConfig = {
    apiKey: "AIzaSyAR3edMCQESqbqPk7-BmERnaNyqfSTMOyw",
    authDomain: "diversset-portfolio.firebaseapp.com",
    databaseURL:
      "https://diversset-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "diversset-portfolio",
    storageBucket: "diversset-portfolio.appspot.com",
    messagingSenderId: "861960906123",
    appId: "1:861960906123:web:b1214019c2226793ab7b8e",
    measurementId: "G-KRVM7BJ1SP",
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true)
      const colRef = collection(db, "articles");
      const snapshots = await getDocs(colRef);
      const docs = snapshots.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const sortedDocs = docs.sort(
        (a, b) => new Date(b.postNumber) - new Date(a.postNumber)
      );
      setArticles(sortedDocs);
      setLoading(false)
    };

    fetchArticles();
  }, []);

  if (loading) {
    return (
        <div style={{ margin: "3rem", textAlign: "center" }}>
          <MoonLoader size={50} color="#888" />
        </div>
      );
  }

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
  };

  const handleBack = () => {
    setSelectedArticle(null);
  };
  function goHome() {
    homeClicked(); 
    navigate("/");
  }

  return (
    <div className="forecaster-container">
      <Helmet>
        <title>{selectedArticle ? selectedArticle.header : "Stock Market Forecast"}</title>
        <meta
          name="description"
          content={
            selectedArticle
              ? selectedArticle.body.slice(0, 160) 
              : "Monthly stock market analytics and forecast."
          }
        />
        <meta name="keywords" content="stock market, analytics, forecast, financial insights, articles, stock market forecast" />
        <meta name="author" content="Sanzhi Kobzhan" />
      </Helmet>
      {adBlockDetected ? (
        <AdsBlocker />
      ) : (
        <div>
          <Ads />
          <div className="upperContentAndButton">
            <button className="tryApp1" onClick={goHome}>
              Home
            </button>
            <h1 className="helpLabel">FORECASTER AND ANALYST</h1>
          </div>
          <h3 className="subHeading">
            Monthly stock market analytics and forecast. My view and opinion.
          </h3>

          {!selectedArticle ? (
            <div className="articles-container">
              {articles.map((article) => (
                <div
                  key={article.id}
                  className="article-card"
                  onClick={() => handleArticleClick(article)}
                >
                  <h3 className="article-title">{article.header}</h3>
                  <p className="article-snippet">
                    {article.body.slice(0, 100)}...
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div className="article-detail">
              <button className="backButton" onClick={handleBack}>
                Back
              </button>
              <div className="detail-content">
  <h2 className="detail-title">{selectedArticle.header}</h2>
  {selectedArticle.body.split("\n").map((paragraph, index) => (
    <p key={index} className="detail-body">
      {paragraph.trim()}
    </p>
  ))}
</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ForecasterAndAnalytics;