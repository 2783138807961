// BlogLst.js
import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import AdsLeftRight from "./AdsLeftRight"
import MoonLoader from "react-spinners/ClipLoader"; 

function extractFirstTwoSentences(body) {
  const textOnly = body.replace(/<[^>]*>/g, "").trim();
  const sentences = textOnly.split(/[.!?]/).filter((s) => s.trim() !== "");
  const firstTwo = sentences.slice(0, 2).join(". ");
  return firstTwo ? `${firstTwo}.` : "";
}

const BlogLst = ({ homeClicked }) => {
  const { heading: routeHeading } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const q = query(collection(db, "blog"), where("heading", "==", routeHeading));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const docSnap = snapshot.docs[0];
          setPost(docSnap.data());
        } else {
          console.log("No post found with heading =", routeHeading);
        }
      } catch (error) {
        console.error("Error fetching blog post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [routeHeading]);

  if (loading) {
    return (
      <div style={{ margin: "3rem", textAlign: "center" }}>
        <MoonLoader size={60} color="#666" />
      </div>
    );
  }
  if (!post) return <div>Post not found.</div>;

  const { heading, body, picture } = post;
  const shortDescription = extractFirstTwoSentences(body || "");

  function goHome() {
    homeClicked(); 
    navigate("/");
  }
 
  const imageSrc = picture ? `data:image/png;base64,${picture}` : null;
  
  return (
    <div>
      <AdsLeftRight />
      <div className="upperContentAndButton">
        <Link to="/" style={{ textDecoration: "none" }}>
          <button
            className="tryApp1"
            onClick={goHome}
            style={{ marginLeft: "40px" }}
          >
            Home
          </button>
        </Link>
      </div>

      <div className="blog-main-container">
        <Helmet>
          <title>{heading} | Guide To The Markets</title>
          <meta name="description" content={shortDescription} />
          <meta property="og:title" content={heading} />
  <meta property="og:description" content={shortDescription} />
  <meta property="og:type" content="article" />
  <meta property="og:url" content={`https://diversset.com/blog/${routeHeading}`} />

  {imageSrc && (
    <meta property="og:image" content={imageSrc} />
  )}


  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content={heading} />
  <meta name="twitter:description" content={shortDescription} />
  {imageSrc && (
    <meta name="twitter:image" content={imageSrc} />
  )}
        </Helmet>

        <h1 className="blog-heading">{heading}</h1>

        {imageSrc && (
          <div className="blog-img-container">
            <img src={imageSrc} alt={heading} className="blog-img" />
          </div>
        )}

        <div
          className="blog-body-content"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </div>
  );
};

export default BlogLst;