import React from "react";
import { useDetectAdBlock } from "adblock-detect-react";

const AdsBlocker = () => {
  const adBlockDetected = useDetectAdBlock();

  // If no ad block, return nothing
  if (!adBlockDetected) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        // Make sure user can't interact with or scroll behind
        pointerEvents: "all",
        backgroundColor: "black",
        color: "white",
        zIndex: 9999, // above everything
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // center the message
        textAlign: "center"
      }}
    >
      <p style={{ margin: "0 40px" }}>
        We have detected that you are using AdsBlocker. Please remove it to use
        Diversset. This app is free but it has ads. Support us by disabling your
        ad blocker. Thank you!
      </p>
    </div>
  );
};

export default AdsBlocker;