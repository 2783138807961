import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/ClipLoader";
import { Helmet } from "react-helmet";

function News({ homeClicked }) {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchNews() {
      try {
        setLoading(true);
        const response = await fetch("https://diversset.com/api/fmp/articles");
        const data = await response.json();
        setArticles(data.content || []);
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchNews();
  }, []);

  if (loading) {
    return (
        <div style={{ margin: "3rem", textAlign: "center" }}>
          <MoonLoader size={50} color="#888" />
        </div>
      );
  }

  function goHome() {
    homeClicked(); 
    navigate("/");
  }

  function openNews(item) {
    navigate(`/news/${encodeURIComponent(item.link)}`);
  }

  return (
    <div style={{ padding: "1rem" }}>
      <Helmet>
        <title>Latest Stock Market and Financial News - Diversset</title>
        <meta
          name="description"
          content="Stay updated with the latest financial news, stock market news, market analytics, and trends from reliable sources. Get insights to make informed decisions."
        />
        <meta name="keywords" content="financial news, market trends, stock updates, analytics, stock market news, stock market analytics, stock market insight" />
        <meta name="author" content="Diversset" />
      </Helmet>
    
      <div style={{ marginBottom: "1.5rem" }}>
        <button
          className="tryApp1"
          onClick={goHome}
          style={{ marginRight: "10px", cursor: "pointer" }}
        >
          Home
        </button>
      </div>

      <h2 className="news-heading">Latest Financial News</h2>

      <ul className="news-grid">
        {articles.map((item) => (
          <li
            className="news-item"
            key={item.link}
            onClick={() => openNews(item)}
            style={{ cursor: "pointer" }} 
          >
           
            {item.image && (
              <img
                src={item.image}
                alt={item.title}
                className="news-image"
              />
            )}

          
            <div className="news-title">
              <strong>{item.title}</strong>
            </div>

            <p className="news-snippet">
              {item.content?.replace(/<[^>]*>/g, "").slice(0, 100)}...
            </p>

            <div className="news-meta">
              <span>{item.date}</span>
              <span>By: {item.author || "Unknown"}</span>
            </div>
          </li>
        ))}
      </ul>

      
      <style>{`
        .news-heading {
          text-align: center;
          margin-bottom: 1.5rem;
        }
        @media (max-width: 768px) {
          .news-heading {
            margin-top: 3rem; /* avoid overlapping with nav on mobile */
          }
        }

        .news-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;
          list-style: none;
          padding-left: 0;
          max-width: 1200px;
          margin: 0 auto;
        }
        @media (max-width: 768px) {
          .news-grid {
            grid-template-columns: 1fr;
          }
        }

        .news-item {
          border-radius: 8px;
          box-shadow: 0 2px 6px rgba(0,0,0,0.1);
          background-color: #fff;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 1rem;
        }
        .news-image {
          width: 100%;
          height: 180px;
          object-fit: cover;
          border-radius: 4px;
          margin-bottom: 0.5rem;
        }
        .news-title {
          font-weight: bold;
          font-size: 1rem;
          color: #333;
          margin-bottom: 0.5rem;
        }
        .news-snippet {
          margin: 0.5rem 0;
          color: #555;
          font-size: 0.9rem;
          text-align: justify;
          flex: 1;
        }
        .news-meta {
          display: flex;
          justify-content: space-between;
          font-size: 0.8rem;
          color: #777;
          margin-top: 0.5rem;
        }
      `}</style>
    </div>
  );
}

export default News;