import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

export const getFilteredAssetsService = async ({ marketCap, beta }) => {
  // 1. Call your Express backend endpoint
  //    If your backend runs at http://localhost:5001,
  //    either use a fully qualified URL or set up a proxy.
  const response = await fetch(
    `https://diversset.com/api/stock-screener?marketCap=${marketCap}&beta=${beta}`
  );

  // 2. Check for errors
  if (!response.ok) {
    throw new Error("data cannot be fetched");
  }

  // 3. Parse the JSON, which is already the `items` array from your backend
  const items = await response.json();

  // 4. Return the items to your Redux thunk or whichever function calls this
  return items;
};

export const getStockPricesService = async ({
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  pastDate,
  currentDate,
}) => {
  // 1. Call your local backend route
  //    If your backend runs at http://localhost:5001, you can do:
  const response = await fetch(
    `https://diversset.com/api/stock-prices?one=${one}&two=${two}&three=${three}&four=${four}&five=${five}&six=${six}&seven=${seven}&eight=${eight}&nine=${nine}&ten=${ten}&pastDate=${pastDate}&currentDate=${currentDate}`
  );

  // 2. Check if the response is OK
  if (!response.ok) {
    throw new Error("data cannot be fetched");
  }

  // 3. Parse the JSON — it will contain assetInfo1..assetInfo10
  const data = await response.json();

  // 4. Return the result to your Redux thunk or wherever you call this
  return data;
};

export const getBlogPostsService = async () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAR3edMCQESqbqPk7-BmERnaNyqfSTMOyw",
    authDomain: "diversset-portfolio.firebaseapp.com",
    databaseURL:
      "https://diversset-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "diversset-portfolio",
    storageBucket: "diversset-portfolio.appspot.com",
    messagingSenderId: "861960906123",
    appId: "1:861960906123:web:b1214019c2226793ab7b8e",
    measurementId: "G-KRVM7BJ1SP",
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const colRef = collection(db, "blog");
  const snapshots = await getDocs(colRef);

  if (snapshots.empty) {
    console.log("no data in the blog");
    return [];
  }
  const docs = snapshots.docs.map((doc) => doc.data());
  const items = docs.map((doc) => {
    return {
      body: doc.body,
      heading: doc.heading,
      picture: doc.picture,
    };
  });
  try {
    // console.log(items);
    return items;
  } catch (error) {
    console.log("no posts in the blog");
  }
};
