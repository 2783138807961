import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, googleProvider } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import "./Auth.scss";

const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fromPath = location.state?.from || "/";

  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        await signOut(auth);
        setMessage("Your email is not verified. Please check your inbox to verify.");
      } else {
        setMessage("Login successful!");
        navigate(fromPath, { replace: true });
      }
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      setMessage("Registration successful! Please check your email for a verification link.");
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setMessage("");
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      if (!user.emailVerified) {
        await signOut(auth);
        setMessage("Your Google email is not verified. Please verify your account first.");
      } else {
        setMessage("Google sign-in successful!");
        navigate(fromPath, { replace: true });
      }
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h2>{isLogin ? "Login" : "Register"}</h2>

        <form onSubmit={isLogin ? handleLogin : handleRegister}>
          <input
            type="email"
            placeholder="Email"
            className="auth-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            className="auth-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="auth-button">
            {isLogin ? "Login" : "Register"}
          </button>
        </form>

        <button onClick={handleGoogleSignIn} className="auth-google-button">
          {isLogin ? "Login with Google" : "Register with Google"}
        </button>

        {message && <p className="auth-message">{message}</p>}

        <div className="auth-toggle">
          {isLogin ? (
            <p>
              Don’t have an account?{" "}
              <span className="auth-link" onClick={() => setIsLogin(false)}>
                Register
              </span>
            </p>
          ) : (
            <p>
              Already have an account?{" "}
              <span className="auth-link" onClick={() => setIsLogin(true)}>
                Login
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Auth;