// AdsLeftRight.js
import React from "react";
import { Adsense } from "@ctrl/react-adsense";

const AdsLeftRight = () => {
  return (
    <div className="ads-container">
      {/* Left Ad */}
      <div className="left-ad">
        <Adsense
          client="ca-pub-7779023812986289"
          slot="6984022506"
          style={{ display: "block" }}
          layout="in-article"
          format="fluid"
        />
        <Adsense />
      </div>

      {/* Right Ad */}
      <div className="right-ad">
        <Adsense
          client="ca-pub-7779023812986289"
          slot="6984022506"
          style={{ display: "block" }}
          layout="in-article"
          format="auto"
        />
        <Adsense />
      </div>

      {/* BOTTOM AD (hidden on desktop, shown on mobile) */}
      <div className="bottom-ad">
        <Adsense
          client="ca-pub-7779023812986289"
          slot="6984022506"
          style={{ display: "block" }}
          layout="in-article"
          format="auto"
        />
        <Adsense />
      </div>
    </div>
  );
};

export default AdsLeftRight;