import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  sendEmailVerification,
} from 'firebase/auth';
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAR3edMCQESqbqPk7-BmERnaNyqfSTMOyw",
  authDomain: "diversset-portfolio.firebaseapp.com",
  databaseURL:
    "https://diversset-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "diversset-portfolio",
  storageBucket: "diversset-portfolio.appspot.com",
  messagingSenderId: "861960906123",
  appId: "1:861960906123:web:b1214019c2226793ab7b8e",
  measurementId: "G-KRVM7BJ1SP",
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Connect to Firestore database
const db = getFirestore(firebaseApp);

export const auth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();

export { db, addDoc, collection };
