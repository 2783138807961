import React, { useState } from "react";
import MyImage from "./stockTargetPrice.png";
import MyImage2 from "./stocks2Buy.png";
import MyImage3 from "./ballyCatcher.png";
import MyImage4 from "./whatBothersU.png";
import { db, addDoc, collection } from "./firebase";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const IOSAppsComponent = ({ homeClicked }) => {
  const [email, setEmail] = useState("");

  const handleButtonClick1 = () => {
    window.location.href =
      "https://apps.apple.com/us/app/stock-target-price/id1589969451";
  };
  const handleButtonClick2 = () => {
    window.location.href =
      "https://apps.apple.com/us/app/stocks-2-buy/id6444135874";
  };
  const handleButtonClick3 = () => {
    window.location.href =
      "https://apps.apple.com/kz/app/bally-catcher/id1658863100";
  };
  const handleButtonClick4 = async () => {
    if (email) {
      try {
        await addDoc(collection(db, "emails"), {
          email: email,
          timestamp: new Date(),
        });
        alert("Email uploaded successfully.");
        setEmail("");
      } catch (error) {
        alert("An error occurred. Please try again.");
      }
    } else {
      alert("Email cannot be empty.");
    }
  };

  return (
    <div>
      <Helmet>
              <title>iOS Stock Analysis Apps</title>
              <meta
                name="description"
                content="Useful apps for stock analysis. analyze your shares, get valuable trade recommendations and define the best time to buy shares"
              />
              <meta name="keywords" content="stock market apps, stock apps ios, ios stock analysis apps, trade ideas apps, best undervalued stocks to buy now, trade ideas, stock target price, stock fair value, stock target price calculator, how to calculate a stock target price, how to read candlestick chart for day trading, trading candlestick patterns cheat sheet, piotroski score, top 10 best stocks to buy now, stocks to buy right now, best dividend stocks to buy now, cheap stocks to buy now, stocks 2 buy, stocks to buy, stocks to sell, when to sell stock, when should i sell my stock, great time to sell shares, when investors should sell their shares" />
              <meta name="author" content="Sanzhi Kobzhan" />
            </Helmet>
      <div className="upperContentAndButton">
        <Link to="/">
          <button className="tryApp1" onClick={homeClicked}>
            Home
          </button>
        </Link>

        <h1 className="helpLabel">iOS APPLICATIONS FOR STOCK INVESTORS </h1>
      </div>

      <div className="contentsWithApps">
        {/* First row: 2 columns */}
        <div className="image-container">
          <div className="image-box">
            <p className="image-description">
              Calculate stock growth potential. Share your investment ideas.
              Buy/sell stocks at the right moment.
            </p>
            {/* 
              The FIRST image => now only 1.3x bigger 
            */}
            <img
              className="appImage enlargedImage"
              src={MyImage}
              alt="Image 1"
            />

            <button className="image-button" onClick={handleButtonClick1}>
              Try the app
            </button>
          </div>

          <div className="image-box">
            <p className="image-description">
              Find great undervalued stocks to buy now using this user friendly
              app.
            </p>
            <img className="appImage" src={MyImage2} alt="Image 2" />

            <button className="image-button" onClick={handleButtonClick2}>
              Try the app
            </button>
          </div>
        </div>

        {/* Second row: 2 columns */}
        {/* <div className="image-container">
          <div className="image-box">
            <p className="image-description">
              Play the game to fight the attacks of panic and anxiety. Calm
              down. Earn 250 scores in the game and get my 11-hour financial
              course for free
            </p>
            <img className="appImage" src={MyImage3} alt="Image 3" />

            <button className="image-button" onClick={handleButtonClick3}>
              Try the app
            </button>
          </div>

          <div className="image-box">
            <p className="image-description">
              Find useful and effective tools that will help you fight your
              panic and anxiety attacks. With this app you will forget how it
              feels to experience continuous waves of panic and anxiety.
            </p>
            <img className="appImage" src={MyImage4} alt="Image 4" />

            <input
              type="text"
              className="text-input"
              placeholder="Leave your email and I will notify you when the app will be ready"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="image-button" onClick={handleButtonClick4}>
              Submit
            </button>
          </div>
        </div> */}
      </div>

      {/* Inline <style> for demonstration; move these rules to .css/.scss in production */}
      <style>{`
        .contentsWithApps {
          margin: 1rem;
        }

        .image-container {
          display: grid;
          grid-template-columns: 1fr 1fr; /* 2 columns per row */
          gap: 1.5rem;
          margin-bottom: 2rem;
        }

        .image-box {
          display: flex;
          flex-direction: column;
          align-items: center; 
          justify-content: space-between;
          background: #fff;
          padding: 1rem;
          border-radius: 8px;
          box-shadow: 0 2px 6px rgba(0,0,0,0.1);
        }

        .image-description {
          margin-bottom: 0.5rem;
          text-align: center;
          flex: 1;
        }

        .appImage {
          max-width: 100%;
          object-fit: cover;
          border-radius: 8px;
          margin-bottom: 0.5rem;
        }

        /* The FIRST image is only 1.3x bigger now */
        .enlargedImage {
          transform: scale(1);
          transform-origin: center;
        }

        /* Buttons greyed out, like a 'Home' style */
        .image-button {
          height: 40px; 
          width: 120px; 
          background-color: #bbb;  /* light grey background */
          color: #333;             /* darker text */
          border: none;
          border-radius: 6px;
          cursor: pointer;
          font-size: 0.9rem;
          margin-top: 0.5rem;
        }
        .image-button:hover {
          background-color: #999; /* slightly darker grey on hover */
        }

        .text-input {
          width: 80%;
          height: 35px;
          border-radius: 6px;
          border: 1px solid #ccc;
          padding: 0 0.5rem;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      `}</style>
    </div>
  );
};

export default IOSAppsComponent;